import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "../images/Logo.svg"
import Apple from "../images/Apple_Pay-Logo.wine.svg"
import Google from "../images/Google_Pay-Logo.wine.svg"
import Master from "../images/Mastercard-Logo.wine.svg"
import Visa from "../images/Visa_Inc.-Logo.wine.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FontAwesome from "./FontAwesome"
FontAwesome()

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="adress">
        <Link to="/">
          <img src={Logo} alt="MV Dépannage informatique" />
        </Link>
        <ul>
          <li>127 Avenue Jean Moulin 83220 Le Pradet</li>
          <li>06 49 73 92 93</li>
          <li>contact@mvdepinformatique.fr</li>
          <Link to="/">
            <li>www.mvdepinformatique.fr</li>
          </Link>
        </ul>
      </div>
      <div className="desc">
        <h4>DEPANNAGE INFORMATIQUE A DOMICILE</h4>
        <h6>
          Dépannage Informatique à domicile, je suis à votre disposition pour
          répondre à vos demandes sur l'aire toulonnaise, dépannage, assistance.
        </h6>
      </div>
      <div className="socialWrapper">
        <a
          href="https://www.facebook.com/MV-Dev-623769154934120"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={["fab", "facebook-f"]} />
        </a>
        <Link to="/mentions">
          <p>Mention legales</p>
        </Link>
        <div>
          <h5>Paiement acceptés</h5>
          <h6>En espèces ou carte bancaire</h6>
          <div className="Pay">
            <ul>
              <li>
                <img src={Visa} alt="Visa" />
              </li>
              <li>
                <img src={Master} alt="Mastercard" />
              </li>
              <li>
                <img src={Apple} alt="Apple Pay" />
              </li>
              <li>
                <img src={Google} alt=" Google Pay" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footerMvdev">
        <p>
          2020 © Création <a href="https://www.mvdev.fr">MVDev</a>
        </p>
      </div>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  width: 100%;
  min-height: 600px;
  min-height: 250px;
  height: auto;
  background: #577ed9;
  color: #e2e8f0;
  padding: 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .adress {
    width: 33%;
    img {
      width: 50%;
      margin: -15% 0;
    }
    h4 {
      margin-bottom: -0.3rem;
    }
    h6 {
      padding-left: 2.3rem;
    }
    a {
      color: #e2e8f0;
    }
    ul li {
      font-family: "Lato", serif;
      margin-bottom: -0.1rem;
    }
    @media screen and (max-width: 479px) {
      width: 90%;
    }
  }

  .desc {
    width: 33%;
    h4 {
      font-family: "Roboto Slab", serif;
      text-align: center;
    }
    h6 {
      font-family: "Lato", serif;
      line-height: 1.3rem;
    }
    @media screen and (max-width: 479px) {
      width: 90%;
    }
  }

  .socialWrapper {
    width: 33%;
    color: #e2e8f0;
    justify-content: center;
    padding: 1rem;
    text-align: center;
    ul {
      display: flex;
      justify-content: center;
    }

    p {
      margin: 4% 0;
      font-size: 1rem;
    }

    a {
      color: #e2e8f0;
      width: 2.3rem;
      font-size: 2rem;
      padding: 0 0.5rem;
      transition: 0.7s ease-in-out;
      cursor: pointer;
    }
    a:hover {
      color: #57d9b2;
    }

    .Pay {
      width: 100%;
      margin: 0 auto;
      text-align: -webkit-center;
      .Pay {
        width: 90%;
        margin: 0 auto;

        ul {
          display: flex;
          width: 180px;
        }
        li {
        }
        img {
          width: 100%;
          padding: 0 -50%;
        }
      }
      .Pay {
        width: 90%;
        margin: 0 auto;

        ul {
          display: flex;
          width: 150px;
        }
        li {
        }
        img {
          width: 100%;
          padding: 0 -50%;
        }
      }
      ul {
        display: flex;
        width: 150px;
      }
      li {
      }
      img {
        width: 100%;
        padding: 0 -50%;
      }
    }
    @media screen and (max-width: 479px) {
      width: 90%;
    }
  }

  .footerMvdev {
    width: 100%;
    margin: 1rem 0 0 0;
    font-family: "Roboto Slab", serif;
    margin: 0 auto;

    p {
      text-align: center;
      margin: 1rem 0 0 0;
      color: #e2e8f0;
    }
    a {
      color: #57d9b2;
      transition: 0.4s ease-in-out;
    }
    a:hover {
      color: #e2e8f0;
    }
  }
`

export default Footer
