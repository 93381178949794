import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "../images/Logo.svg"

const Header = ({ siteTitle }) => {
  return (
    <HeaderWrapper as="header">
      <nav>
        <div className="nav-wrapper">
          <Link to="/">
            <img className="Logo" src={Logo} alt="MV Dépannage Informatique" />
          </Link>
          <ul className="right">
            <li>
              <Link to="/">Acceuil</Link>
            </li>
            {/* <li>
              <Link to="/Tarifs">Tarifs</Link>
            </li> */}
            <li>
              <Link to="/Web">Site Web</Link>
            </li>
            <li>
              <Link to="/Contact">Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  width: 100%;
  height: 80px;

  nav {
    position: relative;
    background: #577ed9;
    margin: 0;
    position: fixed;
    height: 80px;
    z-index: 10000;
  }

  .nav-wrapper {
    background: #577ed9;
    .Logo {
      margin: -7% 0 0 1%;
      width: 20%;
      z-index: 10001;
    }
    ul {
      margin: 0 2% 0 0;
    }
  }

  @media screen and (max-width: 550px) {
    a {
      font-size: 0.7rem;
    }
  }
`

export default Header
